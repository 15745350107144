* {
  margin: 0;
  padding: 0
}


.about-icon-left {
  width: 40px;
}

.project-portfolio .gallery_product .img-box video {
  display: flex;
}

.creative-team .desktop-vis img, .creative-team .mobile-vis img  {
  border-radius: 200px;
}

@media (max-width: 576px) {
  .more-projects .single-proj img, .more-projects .single-proj video {
      height: 117px !important;
  }
}

.about-icon-left1 {
  width: 50px;
}

.about-new-sec p, .about-new-sec p a {
  font-size: 13.5px;
  line-height: 20px;
  margin: 0;
}

.over-img-sec {
  bottom: 100px;
  right: 60px
}

.over-img-sec img {
  width: 50px;
  height: auto;
  object-fit: contain
}

.about-new-sec a {
  /* color: #0000ff !important */
        color: #000 !important;
        text-decoration: underline !important;
}

@media (max-width:768px){
  .about-icon-left {
    max-width: 100%;
}

.about-icon-left1 {
  max-width: 100%;
}
.about-new-sec .col-11 {
    padding-left: 15px
}
.about-new-sec{
  padding: 0 20px;
}
.about-new-sec.mt-3{margin-bottom:20px}
}
.over-img-sec {
  bottom: 30px;
  right: 0;
  justify-content:center !important
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-Bold.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-BoldItalic.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-Italic.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-Medium.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-MediumItalic.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-Regular.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-SemiBold.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(../fonts-new/sans/IBMPlexSans-SemiBoldItalic.woff2) format("woff2"), url(../fonts-new/sans/IBMPlexSans-SemiBoldItalic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-Italic.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-MediumItalic.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-Medium.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-Regular.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-SemiBold.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-SemiBoldItalic.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-SemiBoldItalic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-Bold.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url(../fonts-new/ibm-serif/IBMPlexSerif-BoldItalic.woff2) format("woff2"), url(../fonts-new/ibm-serif/IBMPlexSerif-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap
}

.w-55 {
  width: 280px !important
}

.w-175 {
  width: 371px
}

.color-black {
  color: #000
}

.color-gray {
  color: #999
}

.hov-color-black:hover {
  color: #000
}

.color-lightgray {
  color: #C9C9C9
}

.border-color {
  border-color: #D9D9D9
}

.ibmsans-medium {
  font-family: 'IBM Plex Sans';
  font-weight: 500
}

.ibmserif-medium {
  font-family: 'IBM Plex Serif';
  font-weight: 500
}

.ibmserif-regular {
  font-family: 'IBM Plex Serif';
  font-weight: 400
}

.ibmsans-regular {
  font-family: 'IBM Plex Sans' !important;
  font-weight: 400
}

.ibm-sans {
  font-family: 'IBM Plex Sans'
}

.ibm-serif {
  font-family: 'IBM Plex Serif'
}

.fs-22 {
  font-size: 22px;
  line-height: 28.6px
}

.fs-24 {
  font-size: 24px;
  line-height: 32px
}

.lh-1_3 {
  line-height: 1.3 !important
}

.lh-31 {
  line-height: 31.2px !important
}

.lh-28 {
  line-height: 28px
}

.mb-80 {
  margin-bottom: 80px
}

.pt-16 {
  padding-top: 35px !important
}

.pr-32 {
  padding-right: 32px !important
}

.prl-32 {
  padding: 0 32px
}

.pt-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important
}

.underline-off-2 {
  text-underline-offset: 2px
}

.lh-scope {
  line-height: 20px
}

.flip-1 {
  transform: scaleX(-1)
}

body,
ul,
li,
span,
a,
p {
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Serif';
  font-weight: 600;
  line-height: 1.2em;
  -webkit-font-smoothing: antialiased
}

.nav-icons li a {
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0 5px;
  padding-top: 3px
}


.stickyNav {
  top: -100% !important;
  transition: .9s ease-in-out;
  left: 0;
  right: 0
}

.fixed-topbar {
  top: 0 !important;
  background: #fff !important;
  transition: 0 ease-in-out
}

.navbar-expand-lg,
.navbar {
  transition: top 0s ease-in-out;
  padding-top: 50px;
  padding-bottom: 20px;
  z-index: 15;
  transition-delay: 0;
  width: 100%;
  top: 0
}

.navbar-expand-lg,
.navbar {
  /* background: #fff; */
}

.navbar-expand-lg .container {
  padding: 0 8px !important
}

.stickyNav .navbar-brand img,
.navbar-brand img {
  filter: invert(0);
  transition: filter .5s ease-in-out;
}

.home .navbar-expand-lg .navbar-brand img {
  opacity: 0;
}

.home .header-hidden .navbar-expand-lg .navbar-brand img {
  transition: 0.5s opacity ease-in-out;
}

.home .stickyNav .navbar-brand img {
  filter: invert(0);
  transition: .3s ease-in-out
}

.stickyNav ul.navbar-nav li a.active {
  color: #fff !important
}

.home .header nav.navbar.fixed-topbar {
  padding-top: 50px
}

.home .navbar-expand-lg.bg-none {
  padding-top: 40px !important;
  margin-top: 10px
}

.main-sec {
  z-index: 11;
  position: relative;
  background: #fff !important;
  margin-bottom: 90vh;
  padding-top: 126px
}

#footer {
  position: fixed !important;
  width: 100%;
  bottom: 0;
  z-index: 10
}

.carousel-indicators {
  bottom: 8.5%;
  display: block;
  padding: 0;
  max-width: 100%;
  width: 678px;
  margin: auto 14.2% auto auto !important
}

.carousel-indicators button.active {
  background: #fff !important
}

.carousel-indicators button {
  width: 4px !important;
  height: 4px !important;
  background: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 100px
}

.pv-top-60 {
  padding-top: 60px
}

.pv-60 {
  padding: 60px 32px
}

.pv-bottom-60 {
  padding-bottom: 60px
}

.pv-bottom-36 {
  padding-bottom: 36px
}

.scrollremove {
  overflow: hidden
}

.designation {
  color: #888;
  line-height: 18px
}

.company {
  line-height: 18px
}

.ultra-padding {
  padding: 20px 20px 0
}

.testimonial .carousel-inner {
  width: 1103px;
  margin: auto;
  border-bottom: 1px solid #fff;
  padding: 100px 60px 30px;
  max-width: 100%
}

.testimonial-arrow {
  width: 1103px;
  margin: auto;
  max-width: 100%
}

.testimonial-arrow button {
  position: relative;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 100px;
  opacity: 1
}

.testimonial h6 {
  font-size: 20px
}

.quote-icon:before {
  content: "";
  background-image: url(../img/quote-icon.png);
  width: 178px;
  display: block;
  height: 147px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: calc(61% - 89px)
}

/**Footer CSS**/

#footer .container {
  max-width: 942px;
  margin: auto
}

#footer ul li a {
  font-size: 24px;
  text-transform: uppercase;
  margin: 10px 20px;
  font-weight: 600;
  line-height: 22px;
  display: inline-block;
  color: #000;
}

footer .heading ul li a,
footer .direction span,
.contact-page .direction span,
.email-tag {
  overflow: hidden;
  text-decoration: none;
  width: fit-content;
  display: block;
}

footer .heading ul li a::after,
footer .direction span::after,
.email-tag::after,
.contact-page .direction span::after {
  content: '';
  width: 100%;
  display: block;
  height: 2px;
  background: #000;
  transition: transform 0.5s;
  position: relative;
  transform: scaleX(1);
  transform-origin: bottom left;
}

footer .heading ul li a:hover::after,
footer .direction:hover span::after,
.email-tag:hover::after,
.contact-page .direction:hover span::after {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.social-icons a::after {
  display: none !important
}

.sec-213 .email-tag::after {
  height: 1px
}

footer .direction span {
  font-size: 20px;
  text-decoration: none;
  line-height: 18px
}

footer .direction svg {
  margin-left: 10px;
  width: 15px;
  height: 15px;
}

.site_information .container {
  max-width: 644px !important;
  margin: auto
}

.site_information-1 .container {
  max-width: 80% !important;
  margin: auto
}

.social-links ul li a {
  margin: 0 !important;
  padding-top: 3px;
  filter: invert(1)
}

.social-links ul li:not(:last-child) a {
  margin: 0 8px 0 0 !important
}

.direction i,
.rotate-45 {
  transform: rotate(45deg)
}

#footer {
  margin: 0 0 20px;
  border-radius: 0 0 10px 10px;
  padding: 0 20px
}

.footer-info span {
  z-index: 1;
  position: relative
}

.initiative-img {
  position: absolute;
  left: 0;
  transition: all .5s ease-in-out;
  right: 0;
  bottom: -325px;
  top: auto !important;
  z-index: 100;
}

.footer-info .animate-image:hover .initiative-img {
  transition: all .5s ease-in-out;
  bottom: 0px !important;
}

.mt-103 {
  margin-top: 90px
}

.footer-info {
  background: transparent;
  text-align: center;
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
}

.footer-info span:before {
  display: block;
  content: "";
  background-image: url(../img/initive-arch.png);
  width: 350px;
  position: absolute;
  height: 130px;
  left: calc(50% - 175px);
  bottom: -35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  right: 0
}

@media (min-width:1800px) {
  .initiative-img {
    bottom: -390px;
  }
}

@media (min-width:1400px) and (max-width:1600px) {
  .initiative-img {
    bottom: -300px;
  }
}

/** add css **/
.initiative-img span:before {
  bottom: -9px !important;
  transform: 0.5s bottom ease;
}

.animate-image:hover .initiative-img span:before {
  bottom: -9px !important;
}

.overlay.dis-none {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  z-index: 99;
}

#footer .pv-60 {
  padding: 100px 30px 120px
}

#btn-back-to-top {
  border: none;
  position: fixed;
  bottom: 100px;
  right: 100px;
  display: none;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: #000;
  z-index: 99
}

/**Footer CSS END**/

.intro {
  margin: auto;
  padding: 60px 32px
}

.project-intro {
  margin: auto;
  padding: 60px 32px
}

.letter-space {
  letter-spacing: -1px
}

.contact-page .carousel-indicators {
  margin-left: 15% !important;
}

.contact-page .carousel-indicators button {
  width: 4px !important;
  height: 4px !important
}

.card.creative-head {
  border-radius: 10px 10px 0 0;
  border-top: 14px solid #000 !important
}

.card.creative-head .card-body {
  padding: 136px 130px 75px
}

.card.creative-team.creative-head {
  border-top: 1px solid #D9D9D9 !important;
  border-radius: 0 0 10px 10px;
  border-bottom: 14px solid #000 !important
}

.about-page a {
  text-decoration-color: #000
}

.project-lists {
  border-top: 4px solid #000;
  margin-top: 12px
}

.client-name {
  font-size: 22px;
  line-height: 30px;
  color: #000
}

.common {
  margin-bottom: 10px
}

ul.project-list {
  list-style: none;
  margin: 0 !important;
  padding: 0
}

ul.project-list li {
  font-size: 22px;
  border-bottom: 1px solid #B0B0B0;
  padding: 10px 0;
  color: #000;
  word-wrap: break-word
}

.list-border-none li {
  border-bottom: none !important
}

.client-head {
  font-size: 22px;
  line-height: 40px
}

.project-lists h5 {
  font-size: 40px !important;
  color: #000
}

.fs-18 {
  font-size: 18px
}

.logo-marks .card {
  aspect-ratio: 1;
  background: #00000008;
  border-radius: 0;
  overflow: hidden;
}

.logo-marks:hover img {
  transform: scale(1.2);
  transition: all .5s ease-in-out
}

.logo-marks img {
  transition: all .5s ease-in-out;
  aspect-ratio: 1;
  object-fit: contain;
  width: 100%
}

footer h3.display-2 {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700 !important;
  line-height: 82.8px !important;
  font-size: 72px !important
}

.footer-info span br {
  display: none
}

.mobile-vis {
  display: none
}

.project-breadchrumb a {
  font-size: 32px;
  color: #000
}

.project-breadchrumb span {
  font-size: 32px;
  color: #000;
  text-decoration: underline;
}

.project-page .intro h4,
.project-page .intro h4 a {
  font-size: 40px;
  color: #000;
  text-underline-offset: 3px
}

.project-page .project-intro h4,
.project-page .project-intro h4 a {
  font-size: 40px;
  color: #000;
  line-height: 52px
}

.project-page .back-to-project p,
.project-page a.back-to-project {
  color: #999;
  text-decoration-color: #999;
  transition: 0.5s ease-in-out;
}

a.back-to-project svg {
  color: #999;
  fill: #999;
  transition: 0.5s ease-in-out;
}

.back-to-project,
.back-to-project svg {
  color: #999;
}

.project-page .back-to-project p:hover,
.project-page a.back-to-project:hover,
a.back-to-project:hover,
a.back-to-project:hover svg {
  color: #000;
  fill: #000
}

.project-page .intro p {
  font-size: 20px;
  line-height: 31px
}

.project-page .project-intro p {
  font-size: 20px;
  line-height: 31px
}

.project-page .project-intro.pt-16 p {
  font-size: 24px;
  line-height: 31.2px;
  letter-spacing: -.01em
}

.project-page .read-more-btn {
  font-size: 20px;
  width: 100% !important;
}

.project-page .read-more-btn:hover {
  background-color: #EFEFEF !important;
  border-color: #EFEFEF !important
}

.project-page .read-more-btn svg {
  margin-left: 10px;
  border-color: #d9d9d9
}

.project-page .project-description {
  background: #EFEFEF;
  padding: 40px 32px
}

.project-page .project-description h2 {
  font-size: 40px;
  line-height: 52px
}

.project-page .project-description p {
  font-size: 24px;
  line-height: 31px
}

.project-page .project-description .category li a,
.project-page .project-description .category li .aDiv {
  font-size: 22px;
  line-height: 31px;
  text-decoration: underline;
}

.more-projects .single-proj img,
.more-projects .single-proj video {
  filter: grayscale(1);
  object-fit: cover;
  object-position: center;
  transition: .5s all;
  height: 364px
}

.more-projects .single-proj:hover img,
.more-projects .single-proj:hover video {
  filter: grayscale(0);
  transition: .5s all
}

.more-projects .single-proj .fs-22 {
  text-decoration: none;
  transition: .5s all
}

.more-projects .single-proj:hover .fs-22 {
  text-decoration: underline;
  transition: .5s all
}

.gap-40 {
  gap: 40px
}

.more-projects .single-proj .left-icon {
  top: 160px;
  left: 41px;
  opacity: 0;
  transition: 0.5s all
}

.more-projects .single-proj .right-icon {
  top: 160px;
  right: 41px;
  opacity: 0;
  transition: 0.5s all
}

.more-projects .single-proj:hover .left-icon,
.more-projects .single-proj:hover .right-icon {
  opacity: 1
}

/*
.more-projects .carousel-control-next,.more-projects .carousel-control-prev{width:50px;height:50px;bottom:18%;margin-left:3%!important;margin-right:3%!important}
.more-projects .carousel-control-next .fa,.more-projects .carousel-control-prev .fa{font-size:20px}*/
.more-projects .mt-15 {
  margin-top: 12%
}

.project-page .dis-none {
  transition: all .2s ease-in-out;
  position: absolute;
  top: -500px
}

.project-page .dis-block {
  transition: all .2s ease-in-out;
  margin-bottom: 3rem;
  top: 0;
  position: relative
}

.gallery_product a {
  text-decoration: none
}

.gallery_product .new-tag {
  top: 16px;
  left: 16px;
  border-radius: 100px;
  width: 75px;
  height: 30px;
  line-height: 0;
  z-index: 1;
}

.project-portfolio .gallery_product:hover .img-box img,
.project-portfolio .gallery_product:hover .img-box video,
.home .card:hover .img-box img, .home .card:hover .img-box video {
  transform: scale(1.1);
  transition: .5s transform
}

.home .card .img-box img, .home .card .img-box video {
  width: 100%;
  transition: .5s transform
}

.project-portfolio .gallery_product .img-box,
.home .card .img-box {
  overflow: hidden
}

.project-portfolio .gallery_product .fs-22,
.home .card .fs-22 {
  text-decoration: none;
  transition: .5s all
}

.project-portfolio .gallery_product:hover .fs-22,
.home .card:hover .fs-22 {
  text-decoration: underline;
  transition: .5s all
}

.sec-1 {
  z-index: 0;
  top: 0;
  background: #fff !important
}

.sec-2 {
  margin-top: 100vh;
  z-index: 1;
  padding: 0 8px
}

.project-portfolio .fixed-tabs.dis-none {
  top: -100%
}

.project-portfolio .fixed-tabs.dis-block {
  top: 14%;
  z-index: 15;
  width: 93.3%;
  padding: 40px 0 1%;
  transition: all .9s ease-in-out
}

.project-page-1 .fixed-tabs.dis-none {
  top: -100%;
  width: 100%;
  padding: 40px 7% 1%;
  transition: top .9s ease-in-out
}

.project-page-1 .header-visible .fixed-tabs.dis-none {
  top: auto
}

.project-page-1 .fixed-tabs.dis-block {
  top: auto
}

.product-fix-tabs {
  display: none
}

.project-page-1 .product-fix-tabs {
  display: block
}

.filter-tab .filter-button,
.filter-tab li.list-group-item .nav-link {
  text-align: left;
  text-transform: uppercase;
  border-radius: 0 !important;
  color: #000 !important
}

.filter-tab li.list-group-item a {
  text-decoration: none;
  color: #000
}

.filter-tab li.list-group-item:not(:last-child) {
  margin-right: 50px
}

.filter-tab button span,
.filter-tab li.list-group-item span {
  display: block;
  text-align: left;
  font-size: 29px;
  line-height: 1.1;
  position: relative;
  overflow: hidden
}

.gallery_product {
  padding-left: 15px;
  padding-right: 15px;
  position: relative
}

.list-group li a span {
  text-transform: uppercase
}

.tab-content {
  display: flex;
  flex-wrap: nowrap;
  gap: 0;
  transition: .8s all
}

.tab-pane {
  transform: translate(0%);
  transition: .5s all ease;
  transform-origin: left;
  display: block !important;
  min-width: 100%;
  overflow: hidden;
}

.hidden {
  display: none;
}

.list-group-item:last-child button {
  padding-right: 3px !important
}

#PMD-Slider .carousel-inner .carousel-item .carousel-caption .head,
#PMD-Slider .carousel-inner .carousel-item .carousel-caption .para {
  overflow: hidden
}

#PMD-Slider .carousel-inner .carousel-item .carousel-caption h3 {
  transform: translate(50%, 100%);
  transition: 1s all
}

#PMD-Slider .carousel-inner .carousel-item .carousel-caption p {
  transform: translateY(100%);
  transition: 1s all;
  margin-top: 10px
}

#PMD-Slider .carousel-inner .carousel-item.active .carousel-caption h3,
#PMD-Slider .carousel-inner .carousel-item.active .carousel-caption p {
  transform: translateY(0%);
  transition: 1s all
}

.project-portfolio .tab-content .tab-head a span,
.project-portfolio .tab-content .tab-head a far {
  font-size: 16px !important;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px
}

.backto-pro {
  display: none
}

.project-details .backto-pro {
  display: block
}

.back-to-project.dis-none {
  display: block
}

.header ul.navbar-nav li a {
  overflow: hidden
}

.header ul.navbar-nav li a.active .divider-1 {
  transform: translate(0%) !important;
  background: #000 !important
}

.divider-1 {
  height: 2px;
  background: #000;
  transition: .8s all;
  width: 100%
}

.header .navbar-nav .divider-1 {
  background: #fff;
  margin-top: -11px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #000
}

/**Footer Animaiton**/



@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 1000px
  }

  .intro,
  .project-intro {
    max-width: 100%;
    padding: 60px 32px
  }

  .project-page .project-intro br {
    display: none
  }

  .contact-page .display-5 {
    font-size: 33px
  }

  .contact-page .about-head {
    font-size: 33px
  }

  .project-page .back-to-project p,
  .project-page a.back-to-project {
    font-size: 16px !important;
    line-height: 20px !important;
    color: #999;
    text-decoration-color: #999
  }

  .backto-pro {
    width: 90%;
    margin: auto;
    padding: 30px 8px 0;
    display: none
  }

  .backto-pro .back-to-project {
    margin: 35px auto 0;
    transition: 0.5s all ease-in-out;
  }

  .backto-pro.dis-block {
    background: #fff
  }

  #PMD-Slider .carousel-item.item-2 img {
    object-position: 50% 50%;
    object-fit: cover;
    transform: scale(1)
  }

  #PMD-Slider .carousel-item.item-3 img {
    object-position: center;
    object-fit: cover
  }

  #PMD-Slider .carousel-item.item-1 img {
    object-position: 50% 50% !important;
    object-fit: cover
  }

  #PMD-Slider .carousel-item img,
  #PMD-Slider .carousel-item video {
    width: 100% !important;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 1180px
  }

  .intro,
  .project-intro {
    max-width: 100%;
    padding: 60px 24px 60px 5%
  }

  .project-portfolio .gallery_product img, .project-portfolio .gallery_product video {
    height: 165px !important;
    object-fit: cover;
    object-position: center;
    transition: .5s transform;
    width: 100%
  }

  .contact-page .about-head {
    font-size: 27px
  }

  .client-listings .display-5,
  .logomarks .display-5 {
    font-size: 29px
  }

  .client-listings .project-lists .pl-52,
  .pl-52 {
    padding-left: 52px
  }

  .client-listings .project-lists .project {
    padding: 60px 0
  }

  .mt-n50 {
    margin-top: -50px
  }

  .tab-head.pr-32 {
    padding-right: 0 !important
  }

  .home .divider-1 {
    background-color: #fff !important
  }

  .sec-213 a {
    line-height: 16px
  }

  /**Header Menu Animation**/
  .header .navbar-expand-lg ul li a.nav-link::after {
    content: '';
    width: 0;
    display: block;
    height: 2px;
    background: #999;
    transition: .5s ease
  }

  .header .navbar-expand-lg ul li a.nav-link.active::after {
    width: 100%;
    background: #000
  }

  .header .navbar-expand-lg ul li:hover a.nav-link::after {
    width: 100%;
  }

  .header .navbar-expand-lg .right li a::after {
    content: '';
    width: 100%;
    display: block;
    height: 2px;
    background: #000;
    transition: transform 0.5s;
    position: relative;
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .header .navbar-expand-lg .right li a:hover::after {
    transform: scaleX(0);
    transform-origin: bottom right;
  }

  .home .header .navbar-expand-lg ul li a.nav-link::after,
  .home .header .navbar-expand-lg .right li a::after {
    background: #fff !important
  }

  .home .header.header-visible .navbar-expand-lg ul li a.nav-link::after,
  .home .header.header-visible .navbar-expand-lg .right li a::after {
    background: #000 !important
  }

  .contact-page .email-tag {
    line-height: 23px !important
  }

  .contact-page.about-page .email-tag {
    line-height: 16px !important;
    font-size: 14px !important;
  }

  .contact-page .direction span {
    font-size: 20px;
    line-height: 20px;
    margin-right: 10px
  }

  .single-proj {
    width: 50%;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 90%
  }

  .intro {
    max-width: 68%;
    padding: 60px 0
  }

  .project-intro {
    max-width: 100%;
    padding: 60px 28px 60px 16%
  }

  .home .navbar-expand-lg .social-icons img {
    width: 95%
  }

  .rest-name {
    font-size: 25px !important
  }
}

@media (min-width: 1024px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 90%
  }

  .project-portfolio .gallery_product img, .project-portfolio .gallery_product video {
    height: 230px !important
  }

  .pr-20 {
    padding-right: 20px
  }

  .pl-20 {
    padding-left: 20px
  }

  .project-intro .display-5 {
    font-size: 64px
  }
}

@media (min-width:1600px) {
  #PMD-Slider .carousel-caption {
    bottom: 16%;
    right: 13%
  }

  .intro {
    max-width: 74%;
    padding: 60px 0
  }

  .project-intro {
    max-width: 100%;
    padding: 60px 28px 60px 13%
  }

  .main-sec {
    margin-bottom: 90vh
  }

  .fs-24 {
    font-size: 20px;
    line-height: 27px
  }

  .contact-page .display-5 {
    font-size: 48px
  }

  .contact-page .about-head {
    font-size: 42px;
    line-height: 56px
  }

  .project-portfolio .gallery_product img, .project-portfolio .gallery_product video {
    height: 290px !important;
    transition: .5s all;
    width: 100%
  }
}

@media (min-width:1800px) {
  .intro {
    max-width: 77.5%;
    padding: 60px 0
  }

  .project-intro {
    max-width: 100%;
    padding: 60px 28px 60px 11.4%
  }

  .project-page-1 .fixed-tabs.dis-none {
    padding: 40px 6.5% 1%
  }

  .project-portfolio .gallery_product img, .project-portfolio .gallery_product video {
    height: 330px !important
  }

  .contact-page .about-head {
    font-size: 48px;
    line-height: 62.4px
  }

  .rest-name {
    font-size: 32px !important
  }

  #PMD-Slider .carousel-item.item-1 img {
    object-position: center !important
  }

  #PMD-Slider .carousel-item.item-2 img,
  #PMD-Slider .carousel-item.item-3 img {
    object-fit: cover !important
  }

  #PMD-Slider .carousel-item.item-2 img {
    object-position: center;
    transform: scale(1.2)
  }

  #PMD-Slider .carousel-item.item-3 img {
    object-position: center
  }
}

@media (min-width:1320px) and (max-width:1599px) {
  #PMD-Slider .carousel-caption {
    bottom: 16%;
    right: 7%
  }

  #PMD-Slider .carousel-indicators {
    margin-left: 42.5% !important;
    right: 0
  }

  .intro {
    max-width: 70%
  }
}

@media (min-width:1200px) and (max-width:1320px) {
  #PMD-Slider .carousel-caption {
    bottom: 8%;
    right: 4%
  }

  #PMD-Slider .carousel-indicators {
    bottom: 4%;
    width: 548px
  }
}

@media (min-width:992px) and (max-width:1200px) {
  #PMD-Slider .carousel-item img {
    height: 650px;
    object-fit: cover
  }

  #PMD-Slider .carousel-caption {
    bottom: 8%;
    right: 4%
  }

  #PMD-Slider .carousel-indicators {
    bottom: 4%;
    width: 548px
  }
}

@media (min-width:1056px) and (max-width:1144px) {

  .home .header ul.navbar-nav li:not(:last-child),
  .header ul.navbar-nav li:not(:last-child) {
    margin-right: 3px !important
  }

  .home .header ul.navbar-nav li,
  .header ul.navbar-nav li {
    font-size: 15px
  }

  .header .right ul {
    margin-right: 1em !important
  }

  .header .right .nav-icons {
    margin-right: -1em !important
  }
}

@media (min-width:992px) and (max-width:1056px) {

  .home .header ul.navbar-nav li:not(:last-child),
  .header ul.navbar-nav li:not(:last-child) {
    margin-right: 0 !important
  }

  .home .header ul.navbar-nav li,
  .header ul.navbar-nav li {
    font-size: 13px
  }

  .header .right ul {
    margin-right: 1em !important
  }

  .header .right .nav-icons {
    margin-right: -1em !important
  }


}

@media (min-width:768px) and (max-width:992px) {
  .card.creative-head>.card-body {
    padding: 20px !important
  }

  .creative-head .about-team {
    height: 200px;
    overflow-y: scroll !important;
    overflow: hidden
  }

  .creative-head .col-md-8 .card-body {
    padding: 0 !important
  }

  .creative-head .about-team {
    scrollbar-width: thin;
    scrollbar-color: #000 #f7f7f7
  }

  .creative-head .about-team::-webkit-scrollbar {
    width: 12px
  }

  .creative-head .about-team::-webkit-scrollbar-track {
    background: #f7f7f7
  }

  .creative-head .about-team::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
    border: 2px solid #fff
  }

  footer h3.display-2 {
    line-height: 58.8px !important;
    font-size: 55px !important
  }

  .sec-2 {
    margin-top: 652px
  }
}

@media(min-width:1024px) {
  #PMD-Slider {
    height: calc(100vh - 80px)
  }

  #PMD-Slider .carousel-item img,
  #PMD-Slider .carousel-item video {
    max-height: 100%;
    height: calc(100vh - 80px);
    object-fit: cover;
    object-position: 50% 50%
  }
}

@media (min-width:992px) {
  .nav-icons li:last-child a {
    margin-right: 0
  }

  .header {
    position: fixed !important;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 12
  }

  .header a.navbar-brand {
    width: 97px
  }

  .header .fixed-topbar a.navbar-brand {
    width: auto;
    margin-right: 16px;
    padding-top: 5px;
    padding-bottom: 5px
  }

  .header .fixed-topbar a.navbar-brand img {
    opacity: 1
  }

  .header .bg-none.fixed-topbar a.navbar-brand img {
    opacity: 0
  }

  #PMD-Slider .carousel-inner {
    border-radius: 10px 10px 0 0
  }

  .home .header ul.navbar-nav li:not(:last-child),
  .header ul.navbar-nav li:not(:last-child) {
    margin-right: 15px
  }

  .header .right ul a {
    color: #000;
    line-height: 14px;
    font-weight: 400;
    font-size: 17px
  }

  .header .right ul img {
    margin-right: 15px
  }

  .home .header .stickyNav ul.navbar-nav li a.nav-link {
    color: #000 !important
  }

  .home .header:not(.header-visible) ul.navbar-nav li a.nav-link,
  .header ul.navbar-nav li a.nav-link {
    margin: 0 .6em;
    color: #fff;
    font-weight: 600;
    line-height: 16px;
    font-size: 17px;
    padding: 0
  }

  .home .header:not(.header-visible) ul.navbar-nav li a.nav-link {
    color: #fff
  }

  .header .right .nav-icons ul img {
    margin: 0
  }

  .home .header ul.navbar-nav li a.nav-link,
  .home .header ul.navbar-nav li a.nav-link {
    color: #fff !important
  }

  .home .Logo-area img {
    filter: invert(1)
  }

  .right ul img {
    filter: invert(1) !important
  }

  .fixed-topbar .right ul img,
  .home .stickyNav .right ul img {
    filter: invert(1) !important
  }

  .home .right ul img {
    filter: invert(0) !important
  }

  .home .Logo-area {
    position: relative;
    bottom: 15%
  }

  .home .navbar-expand-lg.fixed-topbar {
    background: #fff !important
  }

  .home .header nav.navbar {
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0
  }

  #PMD-Slider .carousel-item .carousel-caption {
    width: 700px;
    margin: auto;
    margin-right: 0
  }

  .header ul.navbar-nav li a.nav-link {
    color: #999 !important
  }

  .header ul.navbar-nav li a.active {
    color: #000 !important
  }

  .home .header:not(.header-visible) .right ul a {
    color: #fff
  }

  .home .header .stickyNav .right ul a {
    color: #000
  }

  .main-sec {
    margin-bottom: 763px
  }

  .rest-team .card .col-md-4 {
    margin: 12px 0
  }
}

@media (max-width:1400px) {
  .card.creative-head .card-body {
    padding: 100px 60px 60px
  }

  .fs-24 {
    font-size: 18px;
    line-height: 25px !important
  }
}

@media (max-width:767px) {
  #footer ul li a {
    margin-left: 0
  }

  .site_information .container {
    max-width: 100% !important
  }

  #footer .col-sm-6:nth-child(2) {
    order: -1 !important
  }

  .site_information .align-items-start .col-sm-12 {
    border-top: 1px solid;
    padding-top: 20px;
    margin-top: 20px
  }

  .filter-tab ul {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 32px 5px
  }

  .creative-team .w-75 {
    width: 50% !important;
    margin-bottom: -20px
  }

  .project-lists .pt-5 {
    padding-top: 1.5em !important;
    padding-bottom: 2em !important;
    border-bottom: none !important
  }

  .client-listings .tablet-title {
    color: #999;
    font-weight: 400;
    font-size: 18px
  }

  .project-lists {
    border-width: 0px;
    margin-top: 0
  }

  ul.project-list li,
  .client-name {
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    word-break: break-word;
    padding: 4px 0
  }

  .mobile-vis {
    display: block
  }

  .desktop-vis {
    display: none !important
  }

  .project-portfolio .all_tab .gallery_product img, .project-portfolio .all_tab .gallery_product video {
    height: 174px !important;
    object-fit: cover;
    object-position: center;
    width: 100%
  }

  .project-portfolio .scroll .gallery_product img, .project-portfolio .scroll .gallery_product video {
    height: 148px !important;
    object-fit: cover;
    object-position: center;
    width: 100%
  }

  .client-listings .display-5,
  .logomarks .display-5 {
    font-size: 24px
  }

  .fs-18 {
    font-size: 14px;
    line-height: 18px
  }

  .client-listings .project-lists .pl-52 {
    padding-left: 0
  }

  .scroll {
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 5px;
    margin: 0
  }

  .scroll::-webkit-scrollbar,
  .nav-tabs::-webkit-scrollbar {
    display: none
  }

  .scroll .gallery_product {
    width: 263px !important;
    padding: 0 12px 0 0
  }

  .scroll .gallery_product:last-child {
    margin-right: 20px
  }
}

@media (max-width:991px) {

  .fixed-top-1 .navbar-toggler,
  .fixed-top-1 .navbar-brand {
    z-index: 1
  }

  .menu-content .right img {
    filter: invert(1)
  }

  .menu-content .right.mob img {
    filter: invert(0) !important;
  }

  .fixed-top-1 img.collaps-close {
    filter: invert(1)
  }

  .fixed-top-1.stickyNav {
    background: #fff !important
  }

  .fixed-top-1.stickyNav img.collaps-close {
    filter: invert(1) !important
  }

  .fixed-top-1 .logo-invert img {
    filter: invert(1) !important;
    transition: filter 1s ease-in-out
  }

  .fixed-top-1 ul.navbar-nav {
    margin-top: 80px;
    margin-bottom: 40px !important;
    border-bottom: 1px solid #fff;
    padding-bottom: 30px
  }

  .fixed-top-1 .navbar-collapse {
    position: absolute;
    background: #000;
    left: 0;
    right: 0;
    top: -1000px;
    height: 100vh;
    bottom: 0;
    transition: top .5s ease-in-out, left 0.5s, right .5s
  }

  .fixed-top-1 .navbar-collapse.show {
    top: 0
  }

  .fixed-top-1 .navbar-collapse.collapsing {
    height: 100vh !important
  }

  .fixed-top-1 .navbar-collapse .right {
    left: -200%;
    position: relative;
    transition: .5s left
  }

  .scrollremove .fixed-top-1 .navbar-collapse .right {
    left: 0
  }

  .navbar-toggler img.collaps-open,
  .navbar-toggler[aria-expanded="true"] img.collaps-close {
    display: none
  }

  .navbar-toggler[aria-expanded="true"] img.collaps-open {
    display: block
  }

  .fixed-top-1 .navbar-toggler:focus {
    outline: 0 !important;
    box-shadow: none
  }

  nav.navbar.fixed-top-1 {
    padding: 40px 32px 15px;
    position: relative;
    width: 100%
  }

  .fixed-top-1.fixed-topbar {
    position: fixed !important
  }

  .scrollremove .fixed-tabs {
    z-index: -1
  }

  .menu-content {
    height: 100vh;
    margin: 100px 32px 36px;
    border-top: 1px solid #fff;
    padding-top: 24px;
    overflow-y: scroll;
    min-height: 570px !important
  }

  .menu-content a.direction i {
    margin-left: 5px;
    transform: rotate(45deg)
  }

  .fixed-top-1 ul.navbar-nav li.nav-item a {
    font-size: 32px;
    color: #fff;
    line-height: 40px;
    text-decoration: underline;
    font-weight: 600
  }

  #PMD-Slider .carousel-indicators {
    width: auto;
    left: 0;
    right: 0;
    margin: 0 !important;
    padding: 0 40px;
    top: calc(67% - 6px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    bottom: auto
  }

  #PMD-Slider .carousel-item img {
    height: 452px;
    object-fit: cover
  }

  #PMD-Slider .carousel-item .carousel-caption {
    position: relative;
    margin-bottom: 0 !important
  }

  #PMD-Slider .carousel-item .carousel-caption h3,
  #PMD-Slider .carousel-item .carousel-caption p {
    color: #000 !important
  }

  #PMD-Slider .carousel-item .carousel-caption {
    left: 0;
    right: 0;
    padding: 30px 28px;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    margin-bottom: 58px
  }

  #PMD-Slider .carousel-indicators {
    bottom: 0;
    top: auto !important;
    padding: 0 27px
  }

  #PMD-Slider .carousel-indicators button.active {
    background: #000 !important;
    border-color: #000 !important
  }

  #PMD-Slider .carousel-indicators button {
    border-color: #000 !important;
    opacity: 1
  }

  #footer {
    margin: 0 !important;
    padding: 0 !important
  }

  .site_information-1 .container {
    max-width: 100% !important
  }

  .site_information-1 .foot-social {
    text-align: right
  }

  .divider {
    height: 1px;
    background: #000;
    margin: 20px 0
  }

  #PMD-Slider .carousel-item img,
  #PMD-Slider .carousel-item video {
    height: 452px;
    object-fit: cover;
    max-width: 100%
  }


  footer h3.display-2 {
    line-height: 60px !important;
    font-size: 55px !important
  }

  .mt-103 {
    margin-top: 60px
  }

  .Logo-area {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0
  }

  .Logo-area img {
    filter: invert(1)
  }

  .ultra-padding {
    padding: 0 0 30PX
  }

  .scrollremove a.navbar-brand {
    display: block
  }

  .navbar .navbar-brand img {
    width: 100%;
    height: auto
  }

  .home .fixed-top-1 .navbar-brand img {
    filter: invert(1)
  }

  .home .fixed-top-1.fixed-topbar .navbar-brand img {
    filter: invert(0)
  }

  .home .fixed-top-1.stickyNav.fixed-topbar {
    background: #fff !important
  }

  .home .fixed-top-1.stickyNav {
    position: absolute !important;
    background: transparent !important
  }

  .home .fixed-top-1.stickyNav .navbar-brand img {
    filter: invert(1)
  }

  .home .fixed-top-1 img.collaps-close {
    filter: invert(0) !important
  }

  .home .fixed-top-1.fixed-topbar img.collaps-close {
    filter: invert(1) !important
  }

  .home .fixed-top-1.stickyNav.fixed-topbar .navbar-brand img {
    filter: invert(0)
  }

  .home nav.navbar.fixed-top-1 {
    padding: 40px 32px 15px;
    top: 0
  }
}

@media (max-width:576px) {
  .mb-40 {
    margin-bottom: 40px
  }

  .pt-16 {
    padding: 16px 0 0 32px
  }

  .pb-mob-0 {
    padding-bottom: 0
  }

  .pr-mob-32 {
    padding-right: 32px
  }

  #PMD-Slider .carousel-item img {
    height: 452px;
    object-fit: cover
  }

  #PMD-Slider .carousel-item .carousel-caption p {
    font-size: 16px !important;
    line-height: 18px
  }

  #PMD-Slider .carousel-item .carousel-caption h3 {
    font-size: 30px !important;
    line-height: 35px
  }

  footer h3.display-2 {
    font-size: 41px !important;
    line-height: 48px !important
  }

  footer .site_information-1 .foot-logo {
    max-width: 42% !important
  }

  footer .site_information-1 .foot-social {
    max-width: 58% !important;
    padding-left: 30px
  }

  footer .site_information-1 .foot-social ul {
    margin-bottom: 10px
  }

  footer .site_information-1 .foot-logo img {
    width: 100%;
    height: auto
  }

  #footer {
    margin: 0 !important;
    padding: 0;
    border-radius: 0 !important
  }

  footer .social-links ul li:not(:last-child) a {
    margin: 0 5px 0 0 !important
  }

  footer .social-links ul li a {
    width: 27px;
    height: 27px
  }

  #footer .main-text p.fs-4 {
    font-size: 16px !important
  }

  #PMD-Slider .carousel-item img,
  #PMD-Slider .carousel-item video {
    height: 452px;
    object-fit: cover;
    max-width: 100%
  }


  .direction i,
  .rotate-45 {
    margin-left: 8px !important;
    font-weight: 400;
    line-height: 20.7px
  }

  .m-b-up {
    margin-top: 15px !important;
    margin-bottom: 10px !important
  }

  .project-portfolio h1 {
    margin: 0 !important
  }

  .ord-1 {
    order: 1 !important
  }

  .footer-info span::before {
    width: 250px;
    background-size: cover;
    background-position: center;
    height: 85px;
    left: -82px;
  }

  .footer-info span {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    transition: 0.5s right ease-in-out;
  }

  .footer-info span font {
    position: relative;
    top: 5px
  }

  .footer-info {
    background: transparent;
    text-align: center;
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
  }

  .animate-image:hover .initiative-img span:before {
    bottom: -9px !important;
  }


  .footer-info span br {
    display: block
  }

  .col-mob-12 {
    width: 100% !important
  }

  .col-mob-6 {
    width: 42% !important
  }

  .col-6.ful-1 {
    width: 58% !important
  }

  .col-mob-6 img {
    margin-bottom: 0 !important
  }

  .mob-flex {
    display: flex
  }

  #footer ul li a {
    margin-left: 0
  }

  .section-2,
  .section-3,
  .section-4 {
    padding-left: 32px;
    padding-right: 32px
  }

  .section-4 a.fs-5 {
    font-size: 16px !important;
    color: #999 !important
  }

  .testimonial .order-sm-last {
    order: 6 !important
  }

  .testimonial .carousel-inner {
    padding: 75px 0 30px
  }

  .quote-icon:before {
    left: auto;
    right: 0;
    top: 0
  }

  .footer-info {
    padding: 8px 20px;
    border-radius: 0;
    height: 16px;
  }

  .footer-info span:before {
    bottom: -20px;
  }

  .site_information .align-items-start .col-sm-6 {
    margin-top: 20px
  }

  #footer .pv-60 {
    padding: 100px 44px 30px
  }

  .site_information-1 .container {
    max-width: 100% !important;
    padding: 0 13px !important
  }

  .divider {
    height: 1px;
    background: #000
  }

  #btn-back-to-top {
    right: 30px;
    bottom: 30px
  }

  .client-listings .tablet-title {
    font-size: 16px
  }

  .about-team {
    font-size: 14px;
    line-height: 18.2px !important;
    letter-spacing: -.14px;
    font-weight: 400 !important
  }

  .about-team.fs-24 {
    padding: 0 20px
  }

  .cr-head,
  .cr-head span {
    font-size: 16px !important;
    padding-bottom: 0 !important;
    text-align: center;
    position: relative !important
  }

  .sec-213 {
    position: relative !important;
    display: none !important
  }

  .rest-team .sec-213,
  .creative-head .sec-213 {
    display: flex !important
  }

  .accordion .sec-213 {
    display: flex !important;
    margin-top: 5px
  }

  .fs-0 {
    font-size: 12px
  }

  .creative-head .card-body {
    padding: 0 !important
  }

  .w-175 {
    width: 178px;
    margin-top: 54px !important
  }

  .creative-head img.img-fluid.w-65 {
    width: 65%;
    margin-top: 50px
  }

  .display-5.f-m-0 {
    font-size: 21px
  }

  .about-head.f-m-0 {
    font-size: 21px
  }

  .creative-team .mobile-vis .w-75 {
    width: 30% !important;
    margin: 0 10px 0 0
  }

  .creative-head .card-body.m-p-0 {
    padding: 0 !important
  }

  .accordion-button:focus {
    border: none;
    box-shadow: none
  }

  .accordion-button {
    font-size: 16px;
    color: #000
  }

  .contact-page.about-page .accordion-button a {
    font-size: 12px;
    line-height: 10px !important;
    color: #000;
    margin-top: 0
  }

  .accordion-button:not(.collapsed) {
    background: transparent;
    border-top: 1px solid #ddd;
    color: #000;
    box-shadow: none;
    border-radius: 0 !important;
    margin-top: -2px !important
  }

  .accordion-item .accordion-collapse.show {
    border-bottom: 1px solid #ddd
  }

  .rest-team .card .col-md-4 {
    width: 17%;
    padding-right: 0;
    padding-left: 5px;
    margin: 0
  }

  .rest-team .card .col-md-8 {
    width: 83%;
    margin: 0
  }

  .rest-team.m-p-3 {
    padding: 16px 20px
  }

  .creative-team .rest-team h4,
  .creative-team .rest-team a {
    font-size: 12px !important
  }

  .creative-team .rest-team h4 {
    margin-bottom: -3px !important
  }

  .accordion-header p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    font-family: 'ibm plex serif';
  }

  .initiative-img {
    position: absolute;
    left: 0;
    transition: all .5s ease-in-out;
    right: 0;
    bottom: -326px;
    top: auto !important;
    z-index: 100;
  }

  .initiative-img img {
    transition: right .5s ease-in-out;
    width: 430px;
    margin-bottom: 0;
    border-radius: 0 0 5px 5px;
  }

  .footer-info .animate-image:hover .initiative-img {
    right: 0;
    bottom: 0 !important;
  }

  .footer-info .animate-image:hover .initiative-img img {
    border-bottom: 0px;
    border-radius: 0;
  }


  .home .main-sec .container {
    padding: 0 !important
  }

  .header .container {
    padding: 0 !important
  }

  .container {
    padding: 0 !important
  }

  .px-32 {
    padding-right: 32px !important;
    padding-left: 32px !important
  }

  .about-page .container,
  .project-portfolio .container {
    padding: 0 !important
  }

  .project-breadchrumb a {
    font-size: 18px
  }

  .project-breadchrumb span {
    font-size: 18px;
  }

  .project-page .intro {
    padding: 20px 24px
  }

  .project-page .intro h4,
  .project-page .intro h4 a,
  .project-page .project-intro h4,
  .project-page .project-intro h4 a {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0
  }

  .project-page .project-description h2 {
    font-size: 20px;
    line-height: 26px
  }

  .project-page .project-description p {
    font-size: 16px;
    line-height: 20.8px
  }

  .project-page .project-description .position-absolute {
    position: relative !important
  }

  .project-page .read-more-btn {
    width: 100% !important
  }

  .contact-page .carousel-indicators {
    padding-left: 32px;
    margin-left: -0.5% !important;
  }

  .project-lists h5,
  .client-listings h5.fs-3 {
    font-size: 22px !important
  }

  .client-listings .container {
    padding: 0 !important
  }

  .client-head {
    line-height: 15px
  }

  .mt-103 {
    margin-top: 50px
  }

  .main-sec {
    margin-bottom: 825px;
    padding-top: 95px;
  }

  #home-tab-pane .gallery_product {
    padding: 0 11px
  }

  .mob-menu {
    bottom: 10%;
    left: 0;
    padding: 0 32px
  }

  .creative-team .w-75 {
    width: 100% !important;
    margin-bottom: 0
  }

  .project-portfolio .tab-content h3 {
    font-size: 18px !important
  }

  .project-portfolio .tab-content .tab-head a span,
  .project-portfolio .tab-content .tab-head a far {
    font-size: 12px !important
  }

  .project-portfolio .tab-content .tab-head {
    padding-right: 32px
  }

  .filter-tab li.list-group-item .nav-link {
    padding: 0 0 3px !important;
    font-size: 12px;
    line-height: 1
  }

  .gallery_product .new-tag {
    left: 9px;
    top: 9px;
    font-size: 12px;
    width: 50px;
    height: 20px;
    padding: 10px;
  }

  .contact-info .container.px-0 {
    padding: 0 !important
  }

  .project-page-1 .fixed-tabs {
    padding: 50px 0 10px;
    width: 100%
  }

  .sec-2 {
    margin-top: 700px;
    padding: 0
  }

  .sec-1.ultra-padding {
    padding: 0 0 30px
  }

  .footer-info .animate-image:hover .initiative-img {
    bottom: 0 !important
  }

  .home .main-sec .card img {
    width: 100%;
    height: 174px;
    object-fit: cover
  }

  .fs-22 {
    font-size: 18px !important;
    line-height: 23px
  }

  .fs-6 {
    font-size: 14px !important;
    line-height: 17px !important;
    margin-top: 5px
  }

  .fs-5 {
    font-size: 16px !important;
    line-height: 23px !important
  }

  .testimonial.pv-60 {
    padding: 40px 32px
  }

  #testimonial_slider .image-area img {
    margin-right: 15px;
    width: auto;
    height: 86px
  }

  .testimonial-from h6 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500
  }

  .testimonial-from .designation {
    font-size: 14px !important;
    line-height: 17px !important
  }

  #footer .site_information-1 p a.fs-5 {
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .gallery_product {
    padding-left: 12px;
    padding-right: 12px;
    position: relative
  }

  .gallery_product .card img, .gallery_product .card video {
    height: 174px;
    object-fit: cover;
    object-position: center
  }

  .filter-tab button span,
  .filter-tab li.list-group-item span {
    font-size: 22px;
    line-height: 29px
  }

  .filter-tab li.list-group-item:not(:last-child) {
    margin-right: 40px
  }

  .tab-content {
    gap: 0
  }

  .project-portfolio.pv-60 {
    padding: 60px 0 0
  }

  .project-portfolio.logomarks.pv-60 {
    padding: 60px 32px;
  }

  .project-portfolio.pv-60 .about-tab,
  .project-portfolio.pv-60 .contact-tab {
    padding-left: 32px;
    padding-right: 0
  }

  .project-portfolio.pv-60 .all_tab {
    padding-right: 32px;
    background: #fff;
    padding-left: 32px
  }

  .client-listings .project-lists .project {
    border: 0 !important;
    padding: 10px 0 20px
  }

  .pv-top-60 {
    padding-top: 0
  }

  .home .sec-2 .card {
    margin-top: 30px
  }

  .home .col-md-3.mb-4,
  .home .section-3.mb-5.px-lg-0 {
    margin-bottom: 0 !important
  }

  .project-page .back-to-project p {
    margin-bottom: 40px !important
  }

  .project-page .project-intro.pt-16 p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0
  }

  .more-projects .carousel-control-next,
  .more-projects .carousel-control-prev {
    width: 35px;
    height: 35px;
    bottom: 28%
  }

  .more-projects .carousel-control-next .fa,
  .more-projects .carousel-control-prev .fa {
    font-size: 15px
  }

  .more-projects .mt-15 {
    margin-top: 0
  }

  .contact-info .social-links img {
    width: 30px
  }

  .card.creative-head {
    border-radius: 5px 5px 0 0;
    border-top: 5px solid #000 !important
  }

  .card.creative-team.creative-head {
    border-radius: 0 0 5px 5px;
    border-bottom: 5px solid #000 !important
  }

  .project-portfolio .tab-content h3.display-5 {
    font-size: 24px !important
  }

  .divider-1 {
    height: 1.5px
  }

  .fixed-top-1 .backto-pro {
    margin-top: 30px
  }

  .header .fixed-top-1 .backto-pro {
    display: none
  }

  .project-details .header.header-visible .fixed-top-1 .backto-pro {
    display: block
  }

  .client-listings .fs-18 br {
    display: none
  }

  .client-listings .project-lists .project .col-5 {
    width: 38%
  }

  .client-listings .project-lists .project .col-7 {
    width: 62%
  }

  .single-proj p {
    font-size: 14px;
    line-height: normal;
  }

  .project-page .read-more-btn {
    font-size: 14px;
    line-height: 1
  }

  .project-page .read-more-btn svg {
    width: 22px;
    height: auto
  }

  .project-page .read-more-btn.h-auto svg {
    width: 12px;
    height: auto
  }

  .pt-40 {
    padding-top: 40px !important
  }

  .accordion-button::after {
    display: none
  }

  .accordion-button:not(.collapsed) p img {
    transform: rotate(180deg);
    transition: .5s transform
  }

  .accordion-button p img {
    transition: .5s transform;
    margin-left: 5px
  }

  .pl-32 {
    padding-left: 32px !important
  }

  .nav-icons li:first-child a {
    margin-left: 0 !important
  }

  .more-projects .single-proj .left-icon {
    top: 40px;
    left: 20px
  }

  .more-projects .control li svg {
    width: 37px;
    height: 37px
  }

  .more-projects .single-proj .right-icon {
    top: 58px;
    right: 20px;
  }

  footer .direction span {
    font-size: 18px;
  }

  .read-more-btn.dis-none {
    display: none;
  }

  .creative-team .rest-team a {
    line-height: 10px !important;
    margin-top: 8px;
  }

  .contact-page .email-tag {
    line-height: 18px !important
  }

  .contact-page .direction span {
    line-height: 18px;
    margin-right: 0px
  }

  .single-proj {
    width: 100%;
    position: relative;
  }

  .more-projects .single-proj img {
    height: 117px
  }

  .contact-page .creative-head .email-tag {
    font-size: 12px;
    line-height: 12px !important;
    margin-top: 5px
  }

  .desktop-vis.sec-213 {
    display: none !important
  }

  /* .more-projects .single-proj .left-icon{top:39%;left:32px;opacity: 0;transition: 0.5s all}
.more-projects .single-proj .right-icon{top:39%;right:32px;opacity: 0;transition: 0.5s all}*/
}

@media screen and (min-width:430px) and (max-width:576px) {
  #footer .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }
}

.home .bg-none.fixed-topbar {
  background: transparent !important
}

.home .fixed-top-1.bg-none img.collaps-close {
  filter: invert(0) !important
}

.home .fixed-top-1.fixed-topbar.bg-none .navbar-brand img {
  filter: invert(1) !important;
  transition: filter 0 ease-in-out
}

.home .header nav.navbar.bg-none {
  padding-top: 40px;
  background: transparent !important;
}

.trans-00 {
  transition-duration: 0s !important
}

.header {
  transition: top .3s ease-in-out
}

.header-hidden {
  /* transform: translateY(0); */
  transition: top .3s ease-in-out;
  top: -200px !important;
}

.header-visible {
  /* transform: translateY(0); */
  transition: top .3s ease-in-out;
  position: fixed !important;
  top: 0 !important;
  z-index: 12;
  left: 0;
  right: 0;
  background: #fff;
}

.header-visible .navbar .navbar-brand img {
  filter: invert(0) !important
}

.header-visible .navbar .navbar-brand.logo-invert img {
  filter: invert(1) !important
}

.header-visible .navbar .navbar-toggler img {
  filter: invert(1) !important
}

.header-visible .navbar .navbar-toggler img.collaps-open {
  filter: invert(0) !important
}


@media (min-width:991px) {
  .home .header-visible nav.navbar ul.navbar-nav li a.nav-link {
    color: #000 !important
  }
}

.header-visible .right ul img {
  filter: invert(1) !important
}

.header-visible .navbar-expand-lg .navbar-brand img {
  opacity: 1
}

/** custom css **/

.transformed {
  transform: translateX(0%);
}

.notransformed {
  transform: translateX(-200%);
}

.myTabContentProject {
  transform: translateX(0%);
}

.myTabContentScope {
  transform: translateX(-100%);
}

.myTabContentIndustry {
  transform: translateX(-200%);
}

.myTabActivetransform {
  transform: translateX(200%);
}


.home .section-2 .card {
  display: flex;
  justify-content: space-around;

}

.home .section-2 .card .img-box {
  position: relative;
  width: 100%;
  height: 378px;
  overflow: hidden;
}


@media (max-width: 768px) {
  .home .section-2 .card .img-box {
    height: 250px;
  }
}


@media (max-width: 480px) {
  .home .section-2 .card .img-box {
    height: 200px;
  }
}


.home .section-2 .card .img-box img,
.home .section-2 .card .img-box video {
  width: 100%;
  transition: .5s transform;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home .section-3 .card {
  display: flex;
  justify-content: space-around;

}

.home .section-3 .card .img-box {
  position: relative;
  width: 100%;
  height: 558px;
  overflow: hidden;
}


@media (max-width: 768px) {
  .home .section-3 .card .img-box {
    height: 300px;
  }
}


@media (max-width: 576px) {
  .home .section-3 .card .img-box {
    height: 200px;
  }
}


.home .section-3 .card .img-box img,
.home .section-3 .card .img-box video {
  width: 100%;
  transition: .5s transform;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home .section-4 .card {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
}

.home .section-4 .card .img-box {
  position: relative;
  width: 100%;
  height: 178px;
  /* Set your desired height */
  overflow: hidden;
}

.home .section-4 .card .img-box img,
.home .section-4 .card .img-box video {
  width: 100%;
  transition: .5s transform;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.link-fs-22 {
  text-decoration: none;
}

.img-section img {
  width: 100%;
}

.icon-rotate-180 i {
  transform: rotate(180deg)
}

.tab-pane .dis-block {
  transition: all .2s ease-in-out;
  margin-bottom: 3rem;
  top: 0;
  position: relative;
  display: block;
}

.tab-pane .dis-none {
  transition: all .2s ease-in-out;
  position: absolute;
  display: none;
}

.filter-tab#tile-1 .nav {
  position: absolute !important;
  z-index: 1;
  background: #fff !important;
  padding-top: 67px;
  padding-bottom: 10px;
  transition: top 0.3s ease;
  width: 100%;
  left: 0;
  padding-right: 7%;
  top: 65px;
}

.filter-tab#tile-1.header-visible-m .nav {
  top: 15px;
  /* transition: top .3s ease; 
  position: fixed !important;
  width: 100%;*/
  padding-right: 7%;
}

.filter-tab#tile-1.header-hidden-m .nav {
  top: -10px;
  padding-right: 7%;
  /* transition: top 0s;*/
}

.project-page-1 .project-portfolio .tab-content {
  margin-top: 72px;
}

@media (max-width:768px) {
  .filter-tab#tile-1 .nav {
    width: 100%
  }

  .project-page-1 .project-portfolio .tab-content {
    margin-top: 50px;
  }

  .filter-tab#tile-1 .nav {
    width: 100%;
    padding-top: 59px;
    padding-right: 32px;
  }

  .filter-tab#tile-1.header-visible-m .nav {
    top: 10px;
    padding-bottom: 15px;
    width: 100%;
  }
}

.autoHeight {
  height: auto !important;
}

.autoHeightPx {
  height: 0px !important;
}

@media (max-width: 768px) {
  .header {
    position: fixed;
  }
}

.rest-team .card img {
  border-radius: 150px !important;
}

.home .header-hidden .navbar-expand-lg {
  /* background: #fff !important; */
}

@media (max-width: 768px) {
  .header {
    position: fixed;
    z-index: 12;
    width: 100%;
    top: 0;
  }


  .header.header-hidden {
    position: fixed;
    width: 100%;
    z-index: 12;
    /* background: #fff; */
  }
  .header.header-hidden:not(.bg-none) {
    /* background: #fff; */
  }
}

@media (max-width: 991px) {
  .home nav.navbar.fixed-top-1 {
    padding: 40px 32px 15px;
    top: 0;
  }
}

.rest-team .card img {
  width: 100%;
}

.rest-team .card .row {
  width: 105%;
}

@media (max-width:991px) {
  .scrollremove .navbar.fixed-top-1 .navbar-brand img {
    filter: invert(1) !important;
  }
}

.footer-info .initiative-img span font {
  position: relative;
  top: -30px;
}

a.fs-5.color-gray.hov-color-black {
  transition: 0.5s all ease-in-out;
}

@media (max-width:567px) {
  .gallery_product .card img , .gallery_product .card video {
    width: 100%;
  }
}

.project-description {
  overflow: hidden;
  transition: max-height 1s ease-out, opacity 1s ease-out;
  max-height: 0;
  opacity: 0;
  /* margin-top: -105px;*/
  margin-bottom: -80px;
}

.project-description.show {
  max-height: 3000px;
  transition: max-height 1s ease-in, opacity 0.5s ease-in, margin-bottom 1s ease-in;
  opacity: 1;
  margin-bottom: 0px;
  margin-top: -55px;
}

.project-page .dis-none {
  transition: all .2s ease-in-out;
  position: relative;
  /* top: -500px; */
  visibility: hidden;
}

#project-description-wrap {
  width: 254px !important;
  text-align: center;
  max-height: 60px;
  border-radius: 100px !important;
  position: relative;
  z-index: 1;
}

#project-description-wrap.expanded {
  width: 100% !important;
  border-radius: 100px !important;
  transition: width ease-in-out 0.8s, opacity 1.5s ease;
  opacity: 0;
  margin-bottom: -55px;
  background: #EFEFEF !important;
  border: none !important;
  z-index: -1;
}

#PMD-Slider .carousel-inner .carousel-item .carousel-caption h3:after {
  content: '';
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url('../img/head-link.svg');
  border-radius: 100px;
  background-size: cover;
  margin-left: 10px;
}

@media screen and (max-width:991px) {
  #PMD-Slider .carousel-inner .carousel-item .carousel-caption h3:after {
    -webkit-filter: invert(1);
    filter: invert(1);
    width: 25px;
    height: 25px;
    position: relative;
    top: 1px;
    left: 0px;
  }
}


@media (max-width:768px) {
  .footer-info .animate-image:hover span {
    right: 253px;
    transition: right .5s ease-in-out;
  }
}

/* .logo-heading h2 {
  font-size: 48px;
  line-height: 62.4px;
  font-weight: 700;
} */


.logo-heading h2 {
  font-family: IBM Plex Sans;
  font-size: 24px;
  margin-bottom: 0px;
  line-height: 31.2px;
  line-height: 62.4px;
  font-weight: 600;

}

.carousel-item a {
  text-decoration: none !important;
}

.load-more-btn {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  transition: 0.5s all;
}

.load-more-btn:hover {
  color: #000
}

.load-more-btn svg {
  fill: #999;
  transition: 0.5s all;
  margin-left: 10px;
}

.load-more-btn:hover svg {
  fill: #000;
}


.load-more-btn1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  transition: 0.5s all;
}

.load-more-btn1:hover {
  color: #000
}

.load-more-btn1 svg {
  fill: #999;
  transition: 0.5s all;
  margin-left: 10px;
}

.load-more-btn1:hover svg {
  fill: #000;
}


.load-more-btn2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  transition: 0.5s all;
}

.load-more-btn2:hover {
  color: #000
}

.load-more-btn2 svg {
  fill: #999;
  transition: 0.5s all;
  margin-left: 10px;
}

.load-more-btn2:hover svg {
  fill: #000;
}

@media (max-width: 567px) {

  .load-more-btn {
    font-size: 16px;
    margin-bottom: 50px;
  }

  .load-more-btn svg {
    margin-left: 5px;
    margin-top: -4px;
    width: 11px;
  }

  .load-more-btn1 {
    font-size: 16px;
    margin-bottom: 50px;
  }

  .load-more-btn1 svg {
    margin-left: 5px;
    margin-top: -4px;
    width: 11px;
  }

  .load-more-btn2 {
    font-size: 16px;
    margin-bottom: 50px;
  }

  .load-more-btn2 svg {
    margin-left: 5px;
    margin-top: -4px;
    width: 11px;
  }

  .logo-heading h2 {
    font-size: 16px;
    line-height: 1.1em;
    margin-bottom: 15px;
    /* line-height: 20.8px; */
  }

  .project-breadchrumb {
    margin-bottom: 40px !important;
    margin-top: 16px !important;
  }

  #project-description-wrap {
    width: calc(100% - 64px) !important;
  }

  .project-description.show {
    margin-top: -15px !important;
  }

}

.z-inc {
  z-index: 12;
}

.footer-bg {
  background: rgb(239, 239, 239);
  border-bottom: 33px solid #000;
  border-radius: 0 0 20px 20px;
}

@media (max-width: 567px) {
  .footer-info.site_information::after {
    top: auto;
    z-index: 10;
    bottom: 0;
    display: none !important;
  }

  .footer-info span {
    position: absolute;
    top: -412px;
    right: 0;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    transition: right .5s linear;
    z-index: 20;
    /* z-index: 13; */
  }

  .initiative-img {
    /* border-bottom: 0 solid #000; */
    /* top: 0 !important; */
    /* bottom: unset !important; */
    bottom: 0 !important;
    height: 100dvh;
    border-radius: 0;
    /* z-index: 13; */
    z-index: 50;
    transition: right .5s linear;
    right: -253px;
    width: 253px;
    left: auto;
  }

  .initiative-img a {
    display: flex;
    height: 100%;
  }

  .initiative-img  img {
    height: 100%;
    object-fit: cover;
  }

  .footer-info .animate-image:hover .initiative-img {
    bottom: 0px !important;
    top: auto !important;
    z-index: 16;
    transition: right 0.5s linear !important;
  }

  .footer-info .animate-image:hover span {
    right: 254px;
    transition: right .5s linear;
    z-index: 16;
  }
}
.logo-heading h2{
  text-transform: uppercase;
}



.home .header.bg-none .navbar {
  background: transparent !important;
}

.home .header .navbar {
  /* background: #fff; */
}

.home .navbar-expand-lg .navbar-brand img {
  opacity: 1;
  visibility: visible
}

.home .bg-none .navbar-expand-lg .navbar-brand img {
  opacity: 0;
  visibility: hidden;
}

.hidden-up {
  background: #000;
  width: 100%;
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
  transition: transform 0.5s ease;
  transform: translateY(-100%);
}

.hidden-up.show {
  z-index: 1;
  transition: transform 0.5s ease;
  transform: translateY(0%);
}

.offcanvas-header {
  padding: 45px 32px 10px;
}

.offcanvas-header .offcanvas-title img {
  filter: invert(1)
}

.offcanvas-header .btn-close {
  width: auto;
  height: auto;
  opacity: 1;
}

.offcanvas-body {
  overflow: hidden
}


@media (max-width: 991px) {
  .menu-content {
    height: calc(100vh - 145px);
    margin: 25px 32px 36px;
    border-top: 1px solid #fff;
    padding-top: 24px;
    overflow: hidden;
    min-height: 570px !important;
  }

  .home .header-visible .fixed-top-1 img.collaps-close {
    filter: invert(1) !important;
  }

}

@media (max-width: 576px) {
  .mob-menu {
    left: 0;
    padding: 0px 32px;
    bottom: 16%;
  }

  #btn-back-to-top {
    z-index: 11;
  }

  .home .bg-none .fixed-top-1 img.collaps-close {
    filter: invert(1);
  }
}


@media (max-width: 567px) {
  .project-page .read-less-btn {
    width: 100% !important;
  }

  .project-description.show {
    max-width: 3000px;
  }
}

nav.navbar {
  /* background: #fff !important; */
}

@media (max-width: 768px) {
  .footer-bg {
    border-radius: 0;
    border-bottom-width: 16px
  }

  .main-sec {
    margin-bottom: 800px
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .initiative-img {
    bottom: -205px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .initiative-img {
    bottom: -260px;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .initiative-img {
    bottom: -280px;
  }
}

@media (min-width: 1800px) {
  .initiative-img {
    bottom: -380px;
  }
}

.overlay-123 {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.5s opacity ease-in-out;
  /* display: none; */
  /* user-select: none;
  pointer-events: none; */
  z-index: 15;
}
.overlay-123:not(.d-block) {
  user-select: none;
  pointer-events: none;
}
.overlay-123.d-block {
  opacity: 1;
  transition: 0.5s opacity ease-in-out;
}

.back-to-project p {
  /*        transition: 0.5s all ease-in-out;*/
}

.animate-image .initiative-img img {
  /* opacity: 0; */
  transition: 0.5s ease-in-out;
  /* transition: 0.5s opacity ease-in-out; */
}

.animate-image:hover .initiative-img img {
  /* opacity: 1; */
  /* transition: 0.5s opacity ease-in-out; */
}

.contact-page .carousel.slide {
  overflow: hidden;
}

.filter-tab {
  display: none;
}

.project-page-1 .filter-tab {
  display: block;
}

.more-logos {
  max-height: 0;
  transition: 1s max-height ease;
}

.more-logos.show {
  max-height: 1000px;
  transition: 1s max-height ease;
}

/*
.more-logos.trans-123{
    display: contents
}
*/

.more-logos .logo-marks {
  opacity: 0;
  transition: 1s opacity ease;
  width: calc(33.33% - 16px);
}

.more-logos .logo-marks.test {
  opacity: 1;
  transition: 1s opacity ease;
}


.more-logos1 {
  max-height: 0;
  transition: 1s max-height ease;
}

.more-logos1.show {
  max-height: 1000px;
  transition: 1s max-height ease;
}

/*
.more-logos.trans-123{
  display: contents
}
*/

.more-logos1 .logo-marks {
  opacity: 0;
  transition: 1s opacity ease;
  width: calc(33.33% - 16px);
}

.more-logos1 .logo-marks.test {
  opacity: 1;
  transition: 1s opacity ease;
}



.more-logos2 {
  max-height: 0;
  transition: 1s max-height ease;
}

.more-logos2.show {
  max-height: 1000px;
  transition: 1s max-height ease;
}

/*
.more-logos.trans-123{
  display: contents
}
*/

.more-logos2 .logo-marks {
  opacity: 0;
  transition: 1s opacity ease;
  width: calc(33.33% - 16px);
}

.more-logos2 .logo-marks.test {
  opacity: 1;
  transition: 1s opacity ease;
}

@media (max-width: 768px) {
  /*
    .more-logos .logo-marks{
        width: 100%;
    }
    .more-logos1 .logo-marks{
      width: 100%;
  }
  .more-logos2 .logo-marks{
    width: 100%;
}
*/

  #PMD-Slider .carousel-item img,
  #PMD-Slider .carousel-item video {
    max-width: 100%
  }

}

@media (min-width: 576px) and (max-width: 767px) {

  .more-logos .logo-marks,
  .more-logos1 .logo-marks,
  .more-logos2 .logo-marks {
    width: 48%;
  }
}

@media (max-width: 576px) {

  .more-logos .logo-marks,
  .more-logos1 .logo-marks,
  .more-logos2 .logo-marks {
    width: 100%;
  }
}

.contact .contact-page .carousel-item video {
  height: 700px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 1800px) {
  .contact .contact-page .carousel-item video {
    height: 885px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .contact .contact-page .carousel-item video {
    height: 665px;
  }
}

@media screen and (max-width: 768px) {
  .contact .contact-page .carousel-item video {
    height: 500px;
  }
}

@media screen and (max-width: 524px) {
  .contact .contact-page .carousel-item video {
    height: 424px;
  }
}

.about .about-page .carousel-item img {
  height: 700px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 1800px) {
  .about .about-page .carousel-item img {
    height: 885px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .about .about-page .carousel-item img {
    height: 665px;
  }
}

@media screen and (max-width: 768px) {
  .about .about-page .carousel-item img {
    height: 500px;
  }
}

@media screen and (max-width: 524px) {
  .about .about-page .carousel-item img {
    height: 424px;
  }
}

#testimonial_slider .image-area img {
  border-radius: 100%;
}

.new-tag {
  position: absolute;
  width: 75px;
  height: 30px;
  background-color: white;
  color: black;
  padding: 15px 8px;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  /* line-height: 30px; */
  /* Center the text vertically */
}


.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: black;
  margin-bottom: 10px;
}

.logomarks .main-sec{padding-bottom: 50px;}



.previous-element {
  margin-bottom: 20px;
  margin-top: 20px;
}


.intro-client {
  max-width: 68%;
  padding: 20px 0
}

.client-listings {
  padding-top: 0;
  margin-top: 0;
}


 .logofolio {
  padding-top: 0;
  margin-top: 0;
} 

.footer-info span:before {
  display: block;
  content: "";
  background-image: url(../img/initive-arch.svg);
  width: 333.33px;
  position: absolute;
  height: 125px;
  /* left: calc(50% - 175px); */
  bottom: -35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  /* right: 0 */
}

.ph-60 {
  padding-left: 32px;
  padding-right: 32px;
}

.horizontal-line {
  margin-bottom: 15px !important;
  height: 3px;
}

.gallery_product .card{
  position: relative;
}


@media (min-width:768px){
  .tablet-title.client-head {
      padding-left: 15px;
  }
}


.project-details .proDetailHeading {
  margin-bottom: 10px !important;
}

.project-details .proDetailHeading2 {
  margin-bottom: 40px !important;
  margin-top: 0 !important;
}

.project-details .proDetailHeading3 {
  margin-top: 0px !important;
}


@media (max-width: 768px) {
  .project-details .proDetailHeading {
    margin-bottom: 16px !important;
  }
  
  .project-details .proDetailHeading2 {
    margin-bottom: 40px !important;
    margin-top: 0 !important;
  }
}

.project_detail_layout_section {
  /* gap: 1rem; */
  margin-inline: -0.5rem;
  padding-inline: 32px;
}


.project_detail_layout_section .project_sec_div {
  padding: 0.5rem;
  width: 100%;
}

.project_detail_layout_section .project_sec_div:first-child {
  padding-top: 0 !important;
}

@media (min-width: 576px) {
  .project_detail_layout_section {
    padding-inline: 0;
  }
}

@media (min-width: 768px) {
  .project_detail_layout_section {
    /* gap: 3rem; */
    margin-inline: -1.5rem;
    /* padding-inline: 0; */
  }
  .project_detail_layout_section .project_sec_div {
    padding: 1.5rem;
  }
  .project_detail_layout_section .project_sec_div.size-50 {
    width: 50%;
    /* width: calc(50% - 1.5rem); */
  }
  .project_detail_layout_section .project_sec_div.size-33 {
    width: calc(100%/3);
    /* width: calc((100%/3) - 1.5rem); */
  }
  .project_detail_layout_section .project_sec_div.size-66 {
    width: calc(100% - (100%/3));
    /* width: calc((100% - (100%/3)) - 1.5rem); */
  }
  .project_detail_layout_section .project_sec_div.size-50 img,
  .project_detail_layout_section .project_sec_div.size-33 img,
  .project_detail_layout_section .project_sec_div.size-66 img,
  .project_detail_layout_section .project_sec_div.size-50 video,
  .project_detail_layout_section .project_sec_div.size-33 video,
  .project_detail_layout_section .project_sec_div.size-66 video {
    height: 100%;
    object-fit: cover;
  }
}



