.loader-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.loader-container2 {
    display: flex;

    flex-wrap: wrap;
}

.skeleton-stack {
    width: 100%;
    max-width: 30%;
}

.skeleton-wrapper {
    position: relative;
    margin-bottom: 20px;
}


.skeleton-wrapper {
    position: relative;
    margin-bottom: 20px;
}


@media (min-width: 560px) {
    .skeleton-stack {
        max-width: calc(50% - 20px);
    }
}

/* Media Query for larger screens */
@media (min-width: 992px) {
    .skeleton-stack {
        max-width: calc(33.33% - 20px);
        /* Three skeleton loaders in a row */
    }
}

/* Media Query for mobile devices */
@media (max-width: 576px) {
    .skeleton-stack {
        max-width: calc(100% - 20px);
        /* Single skeleton loader taking full width */
    }
}

.horizontal-line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-bottom: 10px;
    /* Space between the line and the skeleton loader */
}

.header-container {
    margin-bottom: 10px;
    /* Space between the header and the skeleton loader */
}

.header-text {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;



}