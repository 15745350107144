/* body .preloader {
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 999;
  pointer-events: none;
}

body .preloader > *:last-child {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-duration: 350ms;
}
body .preloader > * {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-duration: 450ms;
}
body .preloader > *:first-child {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-duration: 550ms;
}

body.loading .preloader {
  pointer-events: all !important;
}

body.loading .preloader > * {
  transform: translate(0, 0%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transition-duration: 0ms;
}

.circle { 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(156 163 175 / 1);
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  position: absolute;
  top: 0px;
  left: -50%;
  right: -50%;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0, -100%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.circle:nth-child(1) { 
  background-color: #fbfbfb; 
  transition-delay: 100ms;
}
.circle:nth-child(2) { 
  background-color: #f1f1f1; 
  transition-delay: 50ms;
}
.circle:nth-child(3) { 
  background-color: #ffffff; 
  transition-delay: 0s;
}
 */




 body .preloader {
  width: 100%;
  height: 100dvh;
  overflow: hidden;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 999;
  pointer-events: none;
}

body .preloader > *:last-child {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.35, 0.35, 1, 1);
  transition-duration: 350ms;
}
body .preloader > * {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.35, 0.35, 1, 1);
  transition-duration: 450ms;
}
body .preloader > *:first-child {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.35, 0.35, 1, 1);
  transition-duration: 550ms;
}

body.loading .preloader {
  pointer-events: all !important;
}

body.loading .preloader > * {
  transform: translate(0, 0%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
  border-bottom-right-radius: 0%;
  border-bottom-left-radius: 0%;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transition-duration: 0ms;
}

.circle { 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(156 163 175 / 0.2);
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  position: absolute;
  top: 0px;
  left: -50%;
  right: -50%;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0, -100%) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.circle:nth-child(1) { 
  background-color: #fbfbfb; 
  transition-delay: 100ms;
}
.circle:nth-child(2) { 
  background-color: #f1f1f1; 
  transition-delay: 50ms;
}
.circle:nth-child(3) { 
  background-color: #ffffff; 
  transition-delay: 0s;
}

.animBoxTest {
  transition: 0.3s ease-in-out;
  animation: scrollBottom 2s linear infinite;
}

.newMoreProjects {
  /* max-height: 0;
  transition: 1s max-height ease; */
  overflow: hidden;
  transition: max-height .8s cubic-bezier(0.04, 1, 1, 1), opacity .8s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.newMoreProjects.show {
  /* max-height: 0;
  transition: 1s max-height ease; */
  /* max-height: 15000px; */
  transition: max-height 1s ease-in-out, opacity 1s ease-in-out;
  opacity: 1;
}

/* @keyframes scrollBottom {
  from {
    transform: translateY(-18px);
  } to {
    transform: translateY(0);
  }
} */





 

