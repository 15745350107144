.project-breadcrumb {
  font-family: Arial, sans-serif; /* Use Arial font or any preferred sans-serif font */
  font-size: 18px; /* Adjust font size as needed */
}

.project-breadcrumb a {
  text-decoration: none; /* Remove underline from links */
  color: #000; /* Set link color to black */
  display: inline-block; /* Ensure each link is on a separate line */
  margin-bottom: 10px; /* Add space between lines */
  margin-top: 10px; /* Add space between the current line and the next one */
  line-height: 18px;
}

.project-breadcrumb a:not(:last-child)::after {
  content: ', '; /* Add comma after each category except the last one */
}

.project-breadcrumb a:last-child::after {
  content: ''; /* Remove comma after the last category */
}

.costomeLineHeight{
  line-height: 60px;
}

.transition-effect {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  opacity: 0;
  transform: translateY(20px);
  display: none;
}

.transition-effect.visible {
  opacity: 1;
  transform: translateY(0);
  display: block; 
}


.hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
}

.dis-block {
  opacity: 1;
  max-height: 100%; 
  transition: all 0.5s ease-in;
}

.transition-effect {
  transition: opacity 0.5s ease-out;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.visible {
  opacity: 1;
  visibility: visible;
}


.additional-projects {
  overflow: hidden;
  transition: max-height .8s cubic-bezier(0.04, 1, 1, 1), opacity .8s ease-in-out;
  max-height: 0; 
  opacity: 0; 
}

.additional-projects.show {
  max-height: 15000px;
  transition: max-height 1s ease-in-out, opacity 1s ease-in-out; 
  opacity: 1;
}

.view-all-link, .view-all-link:visited {
  text-decoration: none; 
  color: inherit; 
}

.view-all-link > span, .view-all-link > span > font {
  text-transform: uppercase;
}


.sticky-nav {
  position: fixed;
  top: 2; 
  left: 0; 
  width: 100%; 
  z-index: 1020; 
  background-color:white; 
}

.filter-tab ul {
margin-top:-68px;
padding-right: 153px; 
}

@media (max-width: 768px) {
.filter-tab ul {
  margin-top: -95px; 
  padding-right: 104px;
  
}
}


@media (max-width: 576px) {
.filter-tab ul {
  margin-top: -60px; 
  padding-right: 104px; 
 
}
}

.project-description {
  overflow: hidden;
  transition: max-height 1s ease-out, opacity 1.1s ease-out, margin 1s ease-out;
  max-height: 0;
  opacity: 0;
  margin-top: -60px;
  margin-bottom: 25px;
}

.project-description.show{
  max-height: 1000px;
  transition: max-height 1s ease-in, opacity 0.5s ease-in, margin-bottom 1s ease-in;
  opacity: 1;
  margin-bottom: 50px;
}
.op-0{
  opacity: 0 !important;
  z-index: -1 !important;
}

.project-page .line-animate:before {
  content: '';
  width: 100%;
  display: block;
  height: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 1s ease-in-out, opacity 0.2s;
}

.project-page .line-animate.open:before{
  transform: scaleX(1);
  opacity: 0;
  transition: transform 1s ease-in-out, opacity 2.5s;
}

.project-page #more_btn_cont{
  z-index: 1;
  transition: 0.5s all;
}

.project-page .read-less-btn {
  font-size: 20px;
}

#project-description-wrap {
  transition: width .8s ease-in-out, opacity 1.5s ease;
  border: 0px !important;
}

.cats-12{
    min-height: 155px;
}

@media (max-width: 567px) {
  .project-page .read-less-btn {
      width: 100% !important;
      font-size: 14px;
      line-height: 1;
  }

  .project-description.show {
      max-height: 3000px;
  }
}

/* .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border: 0 !important;
  font-size: 5em !important;
  height: 154px !important;
  width: 154px !important;
  border-radius: 50% !important;
  background-color: white !important;
  color: #1C1B1F !important;
} */



.video-react-big-play-button::before {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 9;
}

.video-react-big-play-button::after {
  content: '';
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 154px !important;
  width: 154px !important;
  border-radius: 50% !important;
  background-color: white !important;
}
.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  background-color: rgba(#000, 0.4) !important;
}

.video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border: 0 !important;
  font-size: 5em !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 0 !important;
  background-color: rgba(#000, 0.4) !important;
  color: #1C1B1F !important;
}

@media (max-width: 992px) {
  .video-react-big-play-button {
    font-size: 2em !important;
    /* height: 54px !important; */
    /* width: 54px !important; */
  }
  .video-react-big-play-button::after {
    height: 54px !important;
    width: 54px !important;
  }
}